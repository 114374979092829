<template>
    <div>
		<el-table border v-loading="loading" :data="dataList" >
			<el-table-column :label="translate('DICT.ID')" prop="id" min-width="60" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('DICT.CODE')" prop="code" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('DICT.NAME')" prop="name" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('DICT.PARENT_ID')" prop="parentId" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('DICT.VALUE')" prop="value" width="100" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.SORT')" prop="sort" width="60" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.CREATE_TIME')" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.LAST_UPDATE_TIME')" prop="updateTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column :label="translate('COMMON.OPERATION')" align="center" class-name="small-padding fixed-width" width="210" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" @click="handleAdd(scope.row)" v-permission="'BASIC_DICT_INSERT'">{{translate('OPERATION.ADD')}}</el-button>
					<el-button size="mini" type="text" @click="handleUpdate(scope.row)" v-permission="'BASIC_DICT_UPDATE'">{{translate('OPERATION.UPDATE')}}</el-button>
					<el-button size="mini" type="text" @click="handleDelete(scope.row)" v-permission="'BASIC_DICT_DELETE'">{{translate('OPERATION.DELETE')}}</el-button>
				</template>
			</el-table-column>
		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

		<CustomForm :dicts="dicts"
					:on-fresh="showCustomForm"
					:isAdd="isAdd"
					:selectRow="selectRow"
					@on-close="() => showCustomForm = false" ></CustomForm>
	</div>
</template>

<script>
	import {BasicApi} from '@/api';
	import CustomForm from "./CustomForm";
	export default {
		name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await BasicApi.dict.list(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			handleAdd(row) {
				this.showCustomForm = true;
				this.selectRow = row;
				this.isAdd = true;
			},
			async handleUpdate(row) {
				this.showCustomForm = true;
				this.selectRow = row;
				this.isAdd = false;
			},
			handleDelete(row){
				this.$confirm(this.translate('FROM.CONFIRM_DELETE', {module: 'MODULE.DICT', id: row.id}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await BasicApi.dict.remove(row.id);
					if (result.success) {
						this.Message.success(this.translate('FROM.DELETE_SUCCESS'));
						this.getList();
					}
				});
			},
		}
	};
</script>

<style scoped>

</style>
